<template>
    <!--新增对话框-->
    <el-dialog
            :title="editForm.vehicleTypeId?'编辑车辆类型':'新增车辆类型'"
            :visible.sync="dialogVisible"
            width="600px"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form :model="editForm" :rules="editFormRules" ref="editForm">
            <el-form-item label="车辆类型" prop="vehicleType" label-width="100px">
                <el-input v-model="editForm.vehicleType" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="车辆照片" prop="vehiclePicture" label-width="100px">
                <vpload-img-two :limit="1" v-bind:file-list="fileList" :type="1"   @submitImg="getImgList" ></vpload-img-two>
            </el-form-item>
            <el-form-item label="长宽高" prop="lengthWidthHeight" label-width="100px">
                <el-input v-model="editForm.lengthWidthHeight" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="车辆载重" prop="vehicleLoad" label-width="100px">
                <el-input v-model="editForm.vehicleLoad" autocomplete="off">
                    <template slot="append">吨 </template>
                </el-input>
            </el-form-item>
            <el-form-item label="车辆体积" prop="vehicleVolume" label-width="100px">
                <el-input v-model="editForm.vehicleVolume" autocomplete="off">
                    <template slot="append">方</template>
                </el-input>
            </el-form-item>
            <el-form-item label="车辆每公里费用" prop="vehicleMoneyKm" label-width="100px">
                <el-input v-model="editForm.vehicleMoneyKm" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="押车费" prop="parkingFee" label-width="100px">
                <el-input v-model="editForm.parkingFee" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="车型" prop="size" label-width="100px" >
                <el-radio-group v-model="editForm.size">
                    <el-radio :label="1">微型车</el-radio>
                    <el-radio :label="2">轻型车</el-radio>
                    <el-radio :label="3">中型车</el-radio>
                    <el-radio :label="4"> 重型车</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否挂车" prop="isTrailer" label-width="150px" >
                <el-radio-group v-model="editForm.isTrailer">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="排序" prop="sort" label-width="100px">
                <el-input v-model="editForm.sort" autocomplete="off" type="number"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('editForm')">取 消</el-button>
            <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import VploadImgTwo from "../inc/VploadImgTwo";
    export default {
        name: "AddUpdateVehicletype",
        components:{
            VploadImgTwo
        },
        data(){
            return{
                editForm:{},
                avatar:'',
                fileList:[],
                dialogVisible:false,
                ispass:true,
                editFormRules: {
                    vehicleType: [
                        {required: true, message: '请输入车辆类型', trigger: 'blur'}
                    ],
                    lengthWidthHeight: [
                        {required: true, message: '请输入长宽高', trigger: 'blur'}
                    ],
                    vehicleLoad: [
                        {required: true, message: '请输入车辆载重', trigger: 'blur'}
                    ],
                    vehicleVolume: [
                        {required: true, message: '请输入车辆体积', trigger: 'blur'}
                    ],
                    vehiclePicture:[
                        {required: true, message: '请上传车辆照片', trigger: 'blur'}
                    ],
                    vehicleMoneyKm:[
                        {required: true, message: '请输入车辆每公里费用', trigger: 'blur'}
                    ],
                    parkingFee:[
                        {required: true, message: '请输入押车费', trigger: 'blur'}
                    ],
                    size:[
                        {required: true, message: '请选择车型', trigger: 'blur'}
                    ],
                    isTrailer:[
                        {required: true, message: '请选择是否挂车', trigger: 'blur'}
                    ]
                },
            }
        },

        methods:{
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
                this.fileList=[]
            },
            handleClose() {
                this.resetForm('editForm')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.post('/admin/vehicletype/' + (this.editForm.vehicleTypeId?'updateVehicleType' : 'saveVehicleType'), this.editForm)
                            .then(res => {

                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    onClose:() => {
                                        this.$emit('refreshDataList')
                                    }
                                });
                                this.dialogVisible = false
                                this.resetForm(formName)
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getImgList(url,add) {
                if (add==1){
                    this.editForm.vehiclePicture=url
                }else if (add==0){
                    this.editForm.vehiclePicture='';
                }
            },
            init(id){
                this.dialogVisible=true;
                if (id!=null&&id!=''&&id>0){
                    this.$axios.post('/admin/vehicletype/getVehicleTypeInfo', {vehicleTypeId:id} ).then(res => {
                        this.editForm = res.data
                        if (res.data.vehiclePicture){
                            var filess={url:res.data.vehiclePicture}
                            this.fileList.push(filess)
                        }
                    })
                }else {
                    this.editForm.size=2
                    this.editForm.isTrailer=0
                }
            },

        }
    }
</script>

<style scoped>
</style>
